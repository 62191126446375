import React, {useState, useEffect} from "react";
import { Button } from "react-bootstrap";
import Partner from "../components/common/partner";
import '../assets/css/pages/home.css?v=2';
//Modal
import ModalVideo from "../components/modal/video-banner";
import ServicesCircle from "../components/section/services-circle";
// Images/SVG
import ImgInte1 from '../assets/images/feature/integration.png';
import ImgInte2 from '../assets/images/feature/code-integration.png';
// import ImgCon1 from '../assets/images/feature/contract-1.jpg';
// import ImgCon2 from '../assets/images/feature/contract-2.png';
import ImgAna1 from '../assets/images/feature/analytics.png';
// import ImgAna2 from '../assets/images/feature/analytics-graph.png';
// import ImgAna2 from '../assets/images/feature/dashboard-ani-2.svg';
// import ImgAna1 from '../assets/images/feature/dashboard-ani-1.svg';
import ImgAi1 from '../assets/images/feature/asset.png';
import ImgAi2 from '../assets/images/feature/AI.png';
// import ImgAna1 from '../assets/images/feature/dashboard.png';
// import ImgBanner1 from '../assets/images/feature/banner.png';
// import GifBanner1 from '../assets/images/feature/banner.gif';

const LandingPage = () => {
  const [modalToggle, setModalToggle] = useState({video: false});

  const toggleModal = (e, modal_type, toggleState) => {
    try{
      if(toggleState){
        e.preventDefault();
      }
      setModalToggle({...modalToggle, [modal_type]: toggleState});
    }catch(error){
      console.log(error)
    }
  }

  useEffect(() => {
    let words = document.querySelectorAll(".word-re");
    words.forEach(word => {
      let letters = word.textContent.split("");
      word.textContent = "";
      letters.forEach(letter => {
        let span = document.createElement("span");
        span.textContent = letter;
        span.className = "letter";
        word.append(span);
      });
    });

    let currentWordIndex = 0;
    let maxWordIndex = words.length - 1;
    // words[currentWordIndex].style.opacity = "0";

    let rotateText = () => {
      let currentWord = words[currentWordIndex];
      let nextWord = currentWordIndex === maxWordIndex ? words[0] : words[currentWordIndex + 1];
      // rotate out letters of current word
      Array.from(currentWord.children).forEach((letter, i) => {
        setTimeout(() => {
          letter.className = "letter out";
        }, i * 80);
      });
      // reveal and rotate in letters of next word
      nextWord.style.opacity = "1";
      Array.from(nextWord.children).forEach((letter, i) => {
        letter.className = "letter behind";
        setTimeout(() => {
          letter.className = "letter in";
        }, 340 + i * 80);
      });
      currentWordIndex = currentWordIndex === maxWordIndex ? 0 : currentWordIndex + 1;
    };

    rotateText();
    setInterval(rotateText, 2500);

  }, []);

  return (
    <>
    <section className="banner">
      <div className="circle-blue"></div>
      <div className="container-fluid container-lg">
        {/* Header */}
        {/* <Header isOval={true} /> */}
        {/* Heading */}
        <div className="row heading">
          <div className="col-lg-7 col-md-7 col-12 col-sm-12">
            <div className="headline ms-md-2">
            <h1>
              <span className="d-flex justify-content-md-start justify-content-center">Supercharge your&nbsp;<span className="highlight">
                <span className="change-text">
                  <span className="word-re">Travel</span>
                  <span className="word-re">Fintech</span>
                  <span className="word-re">Foodtech</span>
                  <span className="word-re">Mobility</span>
                  <span className="word-re">Consumer</span>
                </span>
              </span>
              </span>
              app into a <span className="curl">SuperBrand</span><br/>
            </h1>
            <p className="sub-heading">Simplify international travel by integrating <span>on-demand</span> services into your app, making every journey delightful!</p>
            <div>
              <Button
                className=""
                variant="primary"
                onClick={(e) => toggleModal(e, 'video', true)}
              >
                See SuperGryd in Action
              </Button>
            </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-4 col-12 col-sm-12 p-0">
            {/* <span>
              <img
                // className="arrow float-end"
                loading="lazy"
                alt="SuperGryd App Sample"
                src={require("../assets/images/bg/banner-main.png")}
              />
            </span> */}
            <div className="mobile-ani-sec justify-content-center">
              <div id="circle1"></div>
              <div id="circle2"></div>
              <div className="square-bg">
                <div className="display-mob">
                <img
                    // className="arrow float-end"
                    className="img-mobile"
                    loading="lazy"
                    alt="SuperGryd App Sample"
                    src={require("../assets/images/bg/banner-main-1.png")}
                  />
                </div>
                <div className="squ-box squ-1">
                  <img src={require('../assets/images/icon/car.png')} alt="Cab" />
                  <span>Book Rides</span>
                </div>
                <div className="squ-box squ-2">
                  <img src={require('../assets/images/icon/food.png')} alt="Food" />
                  <span>Order Food</span>
                </div>
                <div className="squ-box squ-3">
                  <img src={require('../assets/images/icon/experience.png')} alt="Experience" />
                  <span>Experiences</span>
                </div>
                <div className="squ-box squ-4">
                  <img src={require('../assets/images/icon/medical.png')} alt="Medical" />
                  <span>Medical</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Video */}
        {/* <div className="justify-content-center row video-con mt-3 text-center"> */}
          {/* <div className="col-md-9 col-12"> */}
            {/* <img src={GifBanner1} alt="SuperGryd"/> */}
            {/* <div className="iframe-container">
              <iframe
                height="450"
                srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/5Peo-ivmupE/?autoplay=1><img src=https://i.ytimg.com/vi/5Peo-ivmupE/sddefault.jpg alt='SuperGryd'><span><svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%"><path class="ytp-large-play-button-bg" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fill="#f00"></path><path d="M 45,24 27,14 27,34" fill="#fff"></path></svg></span></a>`}
                title="SuperGryd"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen>
              </iframe>
              <div className="btn-cont-1">
                <p className="btn-1">
                  <Button
                    variant="outline-primary"
                    size="md"
                  >
                    <img
                      className=""
                      loading="lazy"
                      alt="Heart"
                      src={require("../assets/images/icon/heart.svg").default}
                    />&nbsp;
                    Health & Wellness
                  </Button>
                </p>
                <p className="btn-2 float-end">
                  <Button
                    variant="outline-primary"
                    size="md"
                  >
                    <img
                      loading="lazy"
                      alt="Car"
                      src={require("../assets/images/icon/car.svg").default}
                    />&nbsp;
                    Ride
                  </Button>
                </p>
              </div>
              <div className="btn-cont-2 pb-0">
                <p className="btn-4 text-end">
                  <Button
                    variant="outline-primary"
                    size="md"
                  >
                    <img
                      loading="lazy"
                      alt="Experiencess"
                      src={require("../assets/images/icon/shines.svg").default}
                    />&nbsp;Experiences</Button>
                </p>
                <p className="btn-3">
                  <Button
                    variant="outline-primary"
                    size="md"
                  >
                    <img
                      loading="lazy"
                      alt="Food"
                      src={require("../assets/images/icon/food.svg").default}
                    />&nbsp;
                    Food
                  </Button>
                </p>
              </div>
            </div> */}
          {/* </div> */}
        {/* </div> */}
      </div>
    </section>
    {/* Goal */}
    <section>
      <div className="container-fluid container-lg">
        <div className="row goal-content justify-content-center">
          <div className="oval"></div>
          <div className="col-xl-7 col-lg-9 col-12 col-sm-10">
            <h2>Our Goal</h2>
            <p className="content">To enable seamless integration of local on-demand services into consumer apps, allowing travelers to access rides, food delivery, experiences, and medical services when they travel worldwide.</p>
          </div>
        </div>
      </div>
    </section>
    {/* Feature */}
    <section id="features">
      <div className="container-fluid container-lg feature">
        <h2 className="title">
          <span className="highlight curl">From Local to Global</span><br/>Elevate your app capabilities, Expand your reach
        </h2>
        <div className="row justify-content-center">
          <div className="col-xl-10 col-12 col-sm-12 col-lg-12">
            <div className="row justify-content-center">
              {/* Code integration */}
              <div className="col-lg-7 col-12 col-sm-10 col-md-6">
                <div className="integration card">
                  {/* <img
                    alt="No code integration"
                    src={require('../assets/images/feature/integration.png')}
                    onMouseEnter={e => e.currentTarget.src = require('../assets/images/feature/code-integration.png')}
                    onMouseLeave={(e) => e.currentTarget.src =  require('../assets/images/feature/integration.png')}
                  /> */}
                  <img
                    alt="No code integration"
                    src={ImgInte2}
                    onMouseEnter={e => e.currentTarget.src = ImgInte2}
                    onMouseLeave={(e) => e.currentTarget.src =  ImgInte1}
                  />
                  <div className="content">
                    <h3>No code integration</h3>
                    <p className="content-lg">Extensive API integration platform and custom SDKs for effortless integrations</p>
                  </div>
                </div>
              </div>
              {/* One Contract */}
              <div className="col-lg-5 col-12 col-sm-10 col-md-6">
                <div className="contract card">
                  <div className="contract-img-sec">
                    <img
                      alt="One Contract"
                      src={require("../assets/images/feature/contract.png")}
                    />
                    <img
                      alt="Signature"
                      src={require("../assets/images/feature/signature.gif")}
                    />
                  </div>
                  <div className="content">
                    <h3>One Contract</h3>
                    <p className="content-sm">A single contract opens up a worldwide network of sellers and hosts</p>
                  </div>
                </div>
              </div>
              {/* Analytics */}
              <div className="col-lg-5 col-12 col-sm-10 col-md-6">
                <div className="analytic card">
                  {/* <img
                    alt="Analytics"
                    src={require("../assets/images/feature/Group427319408.png")}
                  /> */}
                  <img
                    alt="Analytics"
                    src={ImgAna1}
                    // onMouseEnter={e => e.currentTarget.src = ImgAna2}
                    // onMouseLeave={(e) => e.currentTarget.src =  ImgAna1}
                  />
                  <div className="border-circle" id="ana-circle-blu"></div>
                  <div className="content">
                    <h3>Analytics</h3>
                    <p className="content-sm">Comprehensive insights on user behavior and service usage</p>
                  </div>
                </div>
              </div>
              {/* AI Support */}
              <div className="col-lg-7 col-12 col-sm-10 col-md-6">
                <div className="ai card">
                  {/* <img
                    alt="AI-Powered Support"
                    src={require("../assets/images/feature/AI.png")}
                  /> */}
                  <div className="img-content">
                    <img
                      alt="AI-Powered Support"
                      src={ImgAi1}
                    />
                    <img
                      className="img-last"
                      alt="AI-Powered Support"
                      src={ImgAi2}
                    />
                    
                  </div>
                  <div className="border-circle" id="ai-circle-blu"></div>
                  {/* <img
                    alt="AI-Powered Support"
                    src={ImgAi1}
                    onMouseEnter={e => e.currentTarget.src = ImgAi2}
                    onMouseLeave={(e) => e.currentTarget.src =  ImgAi1}
                  /> */}
                  <div className="content">
                    <h3>AI-Powered Support</h3>
                    <p className="content-lg">Overcome language barriers, enable customer delight</p>
                    {/* <br className="d-none d-md-flex"/> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-12 col-sm-12 col-md-7">
          {/* <div className="card">
              <img
                alt="Local to Global"
                src={require("../assets/images/feature/local-to-global.png")}
              />
            </div> */}
          </div>
        </div>
      </div>
    </section>
    {/* Circle */}
    <section className="circle-rotate">
      <div className="square-one">
        <div className="circle-top border-circle"></div>
        <div className="circle-bottom border-circle"></div>
      </div>
      <div className="square-two">
        <div className="circle-top border-circle"></div>
        <div className="circle-bottom border-circle"></div>
      </div>
      <div className="container-fluid container-lg text-center">
        <div className="row justify-content-center">
          <div className="col-lg-9 col-xl-7 col-12 col-sm-10 col-md-8">
            <h2 className="title">
              Integrate <span className="highlight curl">on-demand services</span> within your app
              <p className="text-small-c mb-2">Add our network of ride hailing, food delivery and travel experience partners to your app so your users can avail on-demand services abroad</p>
            </h2>
            <a href="#contactUs">
              <Button
                variant="primary"
                size="lg"
              >Get Started</Button>
            </a>
            <ServicesCircle />
          </div>
        </div>
      </div>
    </section>

    {/* Desc */}
    {/* <section className="super-detail">
      <div className="container-fluid container-lg">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <img src={require("../assets/images/bg/quotation.svg").default} className="first-img" alt="Quotation" />
            <img src={require("../assets/images/bg/quotation.svg").default} className="last-img" alt="Quotation" />
            <p className="text-center">SuperGryd enables seamless integration of local on-demand services into consumer apps, allowing travelers to access rides, food delivery, events, and medical services when they travel worldwide</p>
          </div>
        </div>
      </div>
    </section> */}
    {/* Partner */}
    <Partner />
    <ModalVideo
      isShow={modalToggle.video}
      toggleModal={(e, state) => toggleModal(e, 'video', state)}
    />
    </>
  );
};

export default LandingPage;
